.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: -90px;
}
.main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
  margin-top: -90px;
}

p {
  color: white;
  text-align: justify;
}

.ant-steps-item-title {
  color: #105caa !important;
  font-weight: bold;
}

.login-section {
  background-color: #105caa;
  padding: 20px;
  border-radius: 2px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  flex: 5;
}

.section-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: white;
}
.form-labels {
  color: #105caa;
}
.from {
  text-align: left;
}

.info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 20px;
}

.login-section h3 {
  font-size: 16px;
  text-align: center;
}



.li {
  margin-bottom: 10px; /* Space between list items */
  line-height: 1.5; /* Line height for readability */
  font-size: 16px; /* Adjust font size if needed */
  color: #333; /* Text color */
}

/* Optional: Customize bullet appearance */
.applicant-list {
  list-style-position: outside; /* Bullets outside the list */
}

.applicant-list {
  list-style-type: disc; /* Use bullets for unordered list */
  margin: 0; /* Remove default margins around the list */
  padding: 0; /* Remove default padding around the list */
}

.applicant-list li {
  margin: 10px 0; /* Space between list items (top and bottom) */
  padding: 8px; /* Space inside each list item (all sides) */
  line-height: 1.5; /* Line height for readability */
  font-size: 16px; /* Adjust font size if needed */
  color: #ffffff; /* Text color */
  
}


.required-asterisk{
  color: red;
}

.form-section {
  background-color: #8ec6ff;
  padding: 20px;
  border-radius: 2px;
  color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  flex: 5;
}

.application {
  font-size: 28px;
  margin-bottom: -45px;
  color: white;
  text-align: center;
  margin-top: 15px;
}

.form-label {
  font-weight: bold;
  color: #105caa;
  font-size: 14px;
}

.form-control {
  /* margin-bottom: 10px; */
  color: #105caa;
}

.btn-primary {
  background-color: #007bff;
  border: none;
  align-items: center;
  font-size: 15px;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: "flex";
}
.horizontal-list {
  list-style-type: number;
  display: flex;
  flex-direction: row;
  padding: 0;
  justify-content: space-between;
}

.horizontal-list li {
  max-width: 200px;
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;
}
.horizontal-list li {
  text-align: center;
  margin-right: 20px;
}

.horizontal-list li:last-child {
  margin-right: 0;
}

.active-step {
  color: #105caa;
  font-weight: bold;
}

.ant-select-item-option-content {
  border: none !important;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.h3 {
  text-align: center;
  color: white;
}
.li {
  color: white;
  text-align: justify;
}

.placeholder {
  color: #105caa;
}

.ant-input::placeholder,
.ant-select-selection-placeholder {
  color: #c8c8c8;
  font-size: 12px;
}
.form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
  box-sizing: border-box;
}
.form-control.custom-phone-input {
  width: 80px;
  height: 30px;
  font-size: 12px;
}
.review-container {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.success-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #dff0d8;
  border-color: #d0e9c6;
  color: #3c763d;
  padding: 15px;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.success-icon {
  font-size: 24px;
  margin-right: 15px;
}

.success-text {
  line-height: 1.5;
}
.PhoneInputInput {
  padding-left: 0; /* Adjust padding as needed */
}

.PhoneInputCountry {
  display: none; /* Hide the country code and flag */
}
